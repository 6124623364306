/* trirong-100normal - latin */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Trirong Thin '),
    local('Trirong-Thin'),
    url('./files/trirong-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-100.woff') format('woff'); /* Modern Browsers */
}

/* trirong-100italic - latin */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Trirong Thin italic'),
    local('Trirong-Thinitalic'),
    url('./files/trirong-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-100italic.woff') format('woff'); /* Modern Browsers */
}

/* trirong-200normal - latin */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Trirong Extra Light '),
    local('Trirong-Extra Light'),
    url('./files/trirong-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-200.woff') format('woff'); /* Modern Browsers */
}

/* trirong-200italic - latin */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Trirong Extra Light italic'),
    local('Trirong-Extra Lightitalic'),
    url('./files/trirong-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-200italic.woff') format('woff'); /* Modern Browsers */
}

/* trirong-300normal - latin */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Trirong Light '),
    local('Trirong-Light'),
    url('./files/trirong-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* trirong-300italic - latin */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Trirong Light italic'),
    local('Trirong-Lightitalic'),
    url('./files/trirong-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* trirong-400normal - latin */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Trirong Regular '),
    local('Trirong-Regular'),
    url('./files/trirong-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* trirong-400italic - latin */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Trirong Regular italic'),
    local('Trirong-Regularitalic'),
    url('./files/trirong-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* trirong-500normal - latin */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Trirong Medium '),
    local('Trirong-Medium'),
    url('./files/trirong-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* trirong-500italic - latin */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Trirong Medium italic'),
    local('Trirong-Mediumitalic'),
    url('./files/trirong-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-500italic.woff') format('woff'); /* Modern Browsers */
}

/* trirong-600normal - latin */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Trirong SemiBold '),
    local('Trirong-SemiBold'),
    url('./files/trirong-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* trirong-600italic - latin */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Trirong SemiBold italic'),
    local('Trirong-SemiBolditalic'),
    url('./files/trirong-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* trirong-700normal - latin */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Trirong Bold '),
    local('Trirong-Bold'),
    url('./files/trirong-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* trirong-700italic - latin */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Trirong Bold italic'),
    local('Trirong-Bolditalic'),
    url('./files/trirong-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

/* trirong-800normal - latin */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Trirong ExtraBold '),
    local('Trirong-ExtraBold'),
    url('./files/trirong-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-800.woff') format('woff'); /* Modern Browsers */
}

/* trirong-800italic - latin */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Trirong ExtraBold italic'),
    local('Trirong-ExtraBolditalic'),
    url('./files/trirong-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-800italic.woff') format('woff'); /* Modern Browsers */
}

/* trirong-900normal - latin */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Trirong Black '),
    local('Trirong-Black'),
    url('./files/trirong-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-900.woff') format('woff'); /* Modern Browsers */
}

/* trirong-900italic - latin */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Trirong Black italic'),
    local('Trirong-Blackitalic'),
    url('./files/trirong-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/trirong-latin-900italic.woff') format('woff'); /* Modern Browsers */
}

